import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Container from '../Container'

const bem = new BEMHelper('article')

export default function Article ({
  children,
  small,
  center,
  className,
  ...props
}) {
  return (
    <Container
      nodeType="article"
      {...bem('', { center }, className)}
      medium={!small}
      small={small}
      {...props}
    >
      {children}
    </Container>
  )
}

Article.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  small: PropTypes.bool,
  center: PropTypes.bool,
}

export function Preamble ({ children }) {
  return <p {...bem('preamble')}>{children}</p>
}

Preamble.propTypes = {
  children: PropTypes.any,
}

export function Title ({ children, level }) {
  const Node = `h${`${level}`.replace(/\D/g, '')}`

  return <Node {...bem('title', level)}>{children}</Node>
}

Title.propTypes = {
  children: PropTypes.any,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Title.defaultProps = {
  level: 2,
}

export function Text ({ children, html }) {
  const textProps = html
    ? {
      dangerouslySetInnerHTML: { __html: html },
    }
    : {}

  if (html && html.startsWith('<p>')) {
    const items = html
      .split('</p>')
      .map(string => (string.startsWith('<p>') ? string.substr(3) : string))
      .filter(string => string !== '' && string !== ' ' && string !== ' ')

    if (items.length > 1) {
      return items.map((item, index) => (
        <p
          {...bem('text')}
          key={index}
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))
    }
  }

  return (
    <p {...bem('text')} {...textProps}>
      {children}
    </p>
  )
}

Text.propTypes = {
  children: PropTypes.any,
  html: PropTypes.string,
}

export function Quote ({ children, cite }) {
  return (
    <blockquote {...bem('quote')}>
      <p {...bem('quote-text')}>{children}</p>
      {cite && <cite {...bem('quote-cite')}>{cite}</cite>}
    </blockquote>
  )
}

Quote.propTypes = {
  children: PropTypes.any,
  cite: PropTypes.string,
}

export function List ({ items, title, type, html }) {
  const Node = type === 'ordered' ? 'ol' : 'ul'

  return (
    <>
      {title && <strong {...bem('list-intro')}>{title}</strong>}

      <Node {...bem('list', { ordered: type === 'ordered' })}>
        {items.map((item, index) => {
          if (html) {
            return (
              <li
                key={index}
                {...bem('list-item')}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            )
          }

          return (
            <li key={index} {...bem('list-item')}>
              {!html && item}
            </li>
          )
        })}
      </Node>
    </>
  )
}

List.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  html: PropTypes.bool,
}
