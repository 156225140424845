import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../../components/Hero'
import Article, { Text, Title } from '../../components/Article'

export default function Stationary ({ data: { hero }, ...props }) {
  return (
    <>
      <Hero {...hero} light title="Stationary" />

      <Article>
        <Text>
          We offer efficient transport solutions from door to door. We pick up
          and deliver containers across Europe and along the West-, Mid-, and
          North of Norway.
        </Text>

        <Title level="2">Blue</Title>
        <Text>This. Not that.</Text>
      </Article>
    </>
  )
}

Stationary.propTypes = {
  data: PropTypes.object.isRequired,
}

Stationary.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/fog.jpg/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
